/* Override default react-h5-audio-player styles */
.rhap_container {
  box-shadow: none !important;
  padding: 0 !important;
}

.rhap_progress-bar {
  background-color: rgb(229 231 235) !important;
}

.rhap_progress-filled {
  background-color: rgb(59 130 246) !important;
}

.rhap_progress-indicator {
  background-color: rgb(59 130 246) !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.rhap_progress-indicator:hover {
  transform: scale(1.2) !important;
}

.rhap_button-clear.rhap_play-pause-button {
  transition: all 0.2s ease-in-out !important;
}

.rhap_button-clear.rhap_play-pause-button:hover {
  transform: scale(1.1) !important;
}

.rhap_button-clear.rhap_play-pause-button svg path {
  fill: rgb(59 130 246) !important;
}

/* Ensure consistent spacing */
.rhap_progress-section {
  gap: 8px !important;
}

/* Better time display */
.rhap_time {
  font-family: monospace !important;
  font-size: 12px !important;
  opacity: 0.8 !important;
}

/* Improve focus states */
.rhap_progress-bar:focus-visible,
.rhap_button-clear:focus-visible {
  outline: 2px solid rgb(59 130 246) !important;
  outline-offset: 2px !important;
}

/* Loading state animation */
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.rhap_loading {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Error state styling */
.rhap_error {
  color: rgb(239 68 68);
  font-size: 0.875rem;
  text-align: center;
  padding: 8px;
}
