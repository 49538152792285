.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(37, 99, 235, 0.3) rgba(219, 234, 254, 0.3);
  }
  
  .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: rgba(219, 234, 254, 0.3);
    border-radius: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(37, 99, 235, 0.3);
    border-radius: 8px;
    transition: background-color 0.3s ease;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(37, 99, 235, 0.5);
  }
  
  @supports (-moz-appearance:none) {
    .custom-scrollbar {
      scrollbar-width: thin;
      margin-right: -4px;
    }
  }